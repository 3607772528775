import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { FilterCloseWrap } from './FilterCloseWrap';
import type { OptionType } from '@services';

interface Props extends SelectProps {
  userList: OptionType[];
  onClose?: () => void;
}

export const UserFilter: FC<Props> = ({ userList, onClose, ...props }) => (
  <FilterCloseWrap label="User" onClose={onClose}>
    <Select sx={{ height: '50px' }} placeholder="Select option" {...props}>
      {userList.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FilterCloseWrap>
);
