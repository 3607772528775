import { ReactNode } from 'react';
import { FilterItem } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { ChangeTypeFilter } from '@molecules/Filters';

export const changeTypeFilter: FilterItem = {
  name: 'Change Type',
  key: 'changeType',
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <ChangeTypeFilter
      key="changeType"
      name="Change Type"
      value={filter.changeType}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, changeType: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
};
