import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import { removedCellClassName } from '@helpers';
import { DATE_TIME_FORMAT } from '@constants';
import { InfoCell } from '@atoms';
import type { OptionType, TableHistory } from '@services';

export const columns: GridColDef<TableHistory>[] = [
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { fullName, email, logo } }) => (
      <InfoCell name={fullName} email={email} avatarUrl={logo} />
    ),
  },
  {
    field: 'modifiedDate',
    headerName: 'Modified Date',
    flex: 1,
    cellClassName: removedCellClassName,
    renderCell: ({ row: { modifiedDate } }) => dayjs(modifiedDate).format(DATE_TIME_FORMAT),
  },
  {
    field: 'summaryChanges',
    headerName: 'Summary Changes',
    flex: 2,
    cellClassName: removedCellClassName,
  },
];

export const userList: OptionType[] = [
  {
    value: 'alexander_1',
    label: 'Alexander 1',
  },
  {
    value: 'alexander_2',
    label: 'Alexander 2',
  },
  {
    value: 'alexander_3',
    label: 'Alexander 3',
  },
];

export const mocksData: TableHistory[] = [
  {
    id: 'id_1',
    fullName: 'Alexander 1',
    email: 'alexander1@gmail.com',
    logo: '',
    modifiedDate: '2024-07-18T04:13:10.061Z',
    summaryChanges: 'Change title of articles ',
  },
  {
    id: 'id_2',
    fullName: 'Alexander 2',
    email: 'alexander2@gmail.com',
    logo: '',
    modifiedDate: '2024-07-18T04:13:10.061Z',
    summaryChanges: 'Change content of group articles',
  },
  {
    id: 'id_3',
    fullName: 'Alexander 3',
    email: 'alexander1@gmail.com',
    logo: '',
    modifiedDate: '2024-07-18T04:13:10.061Z',
    summaryChanges: 'Change name of author ',
  },
];
