import { FC } from 'react';
import { Table } from '@molecules';
import type { FilterItem, TableHistory } from '@services';
import { columns, mocksData, userList } from './utils';
import { changeTypeFilter, lastScanningFilter, userFilter } from '@molecules/Table/filters';

const FILTERS_LIST: FilterItem[] = [userFilter(userList), changeTypeFilter, lastScanningFilter];

export const HistoryTable: FC = () => {
  return (
    <Table<TableHistory>
      mocks={mocksData}
      queryName="change-history"
      columns={columns}
      apiRoute=""
      filtersList={FILTERS_LIST}
      searchPlaceholder="Search for made changes"
    />
  );
};
