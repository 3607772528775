import { FC } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { CHANGE_TYPE_OPTIONS } from '@constants';
import { FilterCloseWrap } from './FilterCloseWrap';

interface Props extends SelectProps {
  onClose?: () => void;
}

export const ChangeTypeFilter: FC<Props> = ({ onClose, ...props }) => (
  <FilterCloseWrap label="Change Type" onClose={onClose}>
    <Select sx={{ height: '50px' }} placeholder="Select option" {...props}>
      {CHANGE_TYPE_OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FilterCloseWrap>
);
