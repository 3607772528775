/* eslint-disable react/no-array-index-key */
import { FC, ElementType, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CopyrightIcon from '@mui/icons-material/Copyright';
import HistoryIcon from '@mui/icons-material/History';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import { Notification } from '@molecules';
import { Box, List, ListItemText, ListItemIcon, Divider, Drawer, Tooltip } from '@mui/material';

import { ReactComponent as Logo } from '@assets/sidebar-logo.svg';
import { ReactComponent as ClosedLogo } from '@assets/sidebar-closed-logo.svg';
import { ROUTES, ROUTE_IS_READY } from '@constants';
import { useProfile } from '@hooks/useProfile';

import { SidebarWrap, CollapseButton, ListItemButton } from './styles';

export const Sidebar: FC = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAdmin } = useProfile();
  const navList: Array<
    | {
        Icon: ElementType;
        name: string;
        path?: string;
        activeRoutes: string[]; // NOTE: activeRoutes should be a Regex
      }
    | string
  > = useMemo(
    () => [
      {
        Icon: AccountBoxIcon,
        name: 'Accounts',
        path: `/${ROUTES.Accounts}/${ROUTES.ContentCreators}`,
        activeRoutes: [
          `^/${ROUTES.Accounts}`,
          `^/${ROUTES.ContentCreators}`,
          `^/${ROUTES.BusinessAccounts}`,
          `^/${ROUTES.AssetAccount}`,
        ],
      },
      {
        Icon: NotificationsIcon,
        name: 'Notifications',
        path: `/${ROUTES.Notifications}`,
        activeRoutes: [`/${ROUTES.Notifications}`],
      },
      'divider',
      ...(isAdmin
        ? [
            {
              Icon: SupervisorAccountIcon,
              name: 'Users',
              path: `/${ROUTES.Users}`,
              activeRoutes: [`^/${ROUTES.Users}`],
            },
            {
              Icon: HistoryIcon,
              name: 'History',
              path: `/${ROUTES.History}`,
              activeRoutes: [`^/${ROUTES.History}`],
            },
            ...(ROUTE_IS_READY.ACTION_LOGS
              ? [
                  {
                    Icon: WebStoriesIcon,
                    name: 'Action Logs',
                    path: `/${ROUTES.ActionLog}`,
                    activeRoutes: [`^/${ROUTES.ActionLog}`],
                  },
                ]
              : []),
          ]
        : []),
      'divider',
      {
        Icon: HistoryEduIcon,
        name: 'eSign',
        path: `/${ROUTES.ESign}`,
        activeRoutes: [`^/${ROUTES.ESign}`],
      },
      {
        Icon: CopyrightIcon,
        name: 'Copyright',
        path: `/${ROUTES.CopyrightRegistration}`,
        activeRoutes: [`^/${ROUTES.CopyrightRegistration}`],
      },
    ],
    [isAdmin],
  );

  const onClickNavItem = (path?: string) => {
    if (!path) return;
    navigate(path);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? '250px' : '74px',
        overflowX: 'hidden',
        borderRight: 'none',
        transition: 'width .3s linear',
        '& .MuiPaper-root': {
          width: open ? '250px' : '74px',
          overflowX: 'hidden',
          transition: 'width .3s linear',
          borderRight: 'none',
        },
      }}
    >
      <SidebarWrap>
        <Box>
          <Box mb="34px" ml={open ? '18px' : '11px'}>
            {open ? <Logo /> : <ClosedLogo />}
          </Box>
          <List dense>
            {navList.map((navItem, index) => {
              if (typeof navItem === 'string') {
                return (
                  <Box margin="8px 0" key={navItem + index}>
                    <Divider />
                  </Box>
                );
              }

              const { name, Icon, path, activeRoutes } = navItem;
              const isActive = activeRoutes.some((el) => new RegExp(el).test(pathname));
              return (
                <Tooltip title={!open ? name : ''} placement="right" key={path}>
                  <ListItemButton
                    onClick={() => onClickNavItem(path)}
                    className={isActive ? 'active' : ''}
                  >
                    <ListItemIcon sx={{ minWidth: '32px' }}>
                      <Icon fontSize="medium" />
                    </ListItemIcon>

                    {open && (
                      <ListItemText
                        primary={name}
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                        }}
                      />
                    )}
                  </ListItemButton>
                </Tooltip>
              );
            })}
          </List>
        </Box>
        <Notification isHideIcon />
        <CollapseButton open={open} onClick={() => setOpen(!open)}>
          <ArrowBackIcon />
          {open && 'Collapse'}
        </CollapseButton>
      </SidebarWrap>
    </Drawer>
  );
};
