import { ReactNode } from 'react';
import { FilterItem } from '@services';
import { SelectChangeEvent } from '@mui/material';
import { UserFilter } from '@molecules';
import type { OptionType } from '@services';

export const userFilter = (userList: OptionType[]): FilterItem => ({
  name: 'User',
  key: 'user',
  getFilter: (onChange, onClose, setFilters, filter): ReactNode => (
    <UserFilter
      key="user"
      name="User"
      value={filter.user}
      userList={userList}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setFilters({ ...filter, user: event.target.value as string })
      }
      onClose={onClose}
    />
  ),
});
