import { Box, Typography } from '@mui/material';
import { InnerView } from '@templates';
import { HistoryTable } from '@organisms/Tables/History/HistoryTable';

export function History() {
  return (
    <>
      <Box mb="16px" display="flex" justifyContent="flex-start">
        <Typography variant="h4">History</Typography>
      </Box>
      <InnerView>
        <HistoryTable />
      </InnerView>
    </>
  );
}
